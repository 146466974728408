import SearchComponent from '../components/SearchComponent';

const Search = () => {
    return (
        <>
            <SearchComponent />
        </>
    );
};

export default Search;
